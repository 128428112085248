import React from 'react'
import { LayoutProps } from './LayoutProps';

const DefaultLayout = ({ children }: LayoutProps) => (
  <div className="bg-white">
    {children}
  </div>
);

export default DefaultLayout;
