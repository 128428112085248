import { DocumentType } from "@reducers/OnboardingReducer";

export enum OnboardingStatus {
  NOT_SUBMITTED = 'not_submitted',
  SUBMITTED = 'submitted',
  VERIFIED = 'verified',
  BLOCKED = 'blocked',
}

export interface OnboardingType {
  status: OnboardingStatus
  payout_settings: PayoutDetailsPayloadType | null
  business_details: BusinessDetailsPayloadType | null
  business_documents: BusinessDocumentsResponseType | null
  business_representative: RepresentativeResponseType | null
  business_structure: BusinessStructurePayloadType | null
  credit_card_statement: CreditCardStatementPayloadType | null
  statement_of_acceptance: SoaPayloadType | null
}

export interface BusinessStructurePayloadType {
  country: string
  address_line1: string
  address_line2: string | null
  city: string
  province: string
  zip: string
  business_type: string
}

export interface RepresentativeResponseType {
  first_name: string
  last_name: string
  date_of_birth: string //format: YYYY-MM-DD
  country: string
  address_line1: string
  address_line2: string | null
  city: string
  province: string
  zip: string
  id_type: string
  id_number: string
  valid_ids: DocumentType[]
}

export interface RepresentativePayloadType {
  first_name: string
  last_name: string
  date_of_birth: string //format: YYYY-MM-DD
  country: string
  address_line1: string
  address_line2: string | null
  city: string
  province: string
  zip: string
  id_type: string
  id_number: string
}

export interface BusinessDetailsPayloadType {
  legal_business_name: string | null
  doing_business_name: string | null
  industry: string
  business_website: string
  product_description: string
}

export interface BusinessDocumentsPayloadType {
  dti_business_certificate_number: string | null
  sec_company_registration_number: string | null
  date_of_incorporation: string | null
}

export interface BusinessDocumentsResponseType {
  dti_business_certificate_number: string | null
  sec_company_registration_number: string | null
  date_of_incorporation: string | null
  dti_certificates: DocumentType[]
  articles_of_partnerships: DocumentType[]
  notarized_secretary_certificates: DocumentType[]
  articles_of_incorporations: DocumentType[]
  by_laws_documents: DocumentType[]
  sec_certificate_of_incorporations: DocumentType[]
}

export interface OwnerPayloadType {
  first_name: string
  last_name: string
  country: string
  address_line1: string
  address_line2: string
  city: string
  province: string
  zip: string
  date_of_birth: string
}

export interface CreditCardStatementPayloadType {
  statement_descriptor: string
  customer_support_phone_number: string
}

export interface PayoutDetailsPayloadType {
  bank: string
  account_name: string
  account_number: string
  payout_delivery?: string
  payout_schedule?: string
}

export interface SoaPayloadType {
  ip: string | null;
  date_accepted: string;
  user_agent: string | null;
  location: string | null;
}