import {
  SET_USER_2FA_AUTHENTICATOR_ENABLED,
  SET_USER_2FA_SMS_ENABLED,
  SET_USER_AUTHENTICATOR_BACKUP,
  SET_USER_AUTHENTICATOR_SECRET,
  SET_USER_EMAIL,
  SET_USER_FIRST_NAME,
  SET_USER_ID,
  SET_USER_LAST_NAME,
  SET_USER_PHONE,
  SET_USER_ROLE,
  SET_USER_TOKEN
} from './types'

export interface UserState {
  id?: string | null;
  accessToken?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  role?: string | null;
  enabled2faSMS: boolean;
  enabled2faAuthenticator: boolean;
  authenticatorSecret?: string | null;
  authenticatorBackup?: string | null;
}

export type UserAction =
  | {
    type:
    | 'SET_USER_ID'
    | 'SET_USER_TOKEN'
    | 'SET_USER_FIRST_NAME'
    | 'SET_USER_LAST_NAME'
    | 'SET_USER_EMAIL'
    | 'SET_USER_PHONE'
    | 'SET_USER_ROLE'
    | 'SET_USER_AUTHENTICATOR_SECRET'
    | 'SET_USER_AUTHENTICATOR_BACKUP'
    payload?: string | null
  }
  | {
    type:
    | 'SET_USER_2FA_SMS_ENABLED'
    | 'SET_USER_2FA_AUTHENTICATOR_ENABLED';
    payload: boolean
  }

export const initialState: UserState = {
  id: null,
  accessToken: null,
  firstName: 'Jerick',
  lastName: 'Coneras',
  email: 'jerick@magpie.im',
  phone: '+639175134281',
  role: 'administrator',
  enabled2faSMS: false,
  enabled2faAuthenticator: false,
  authenticatorSecret: null,
  authenticatorBackup: null,
}

const userReducer = (state: UserState, action: UserAction) => {
  switch (action.type) {
    case SET_USER_ID:
      return {
        ...state,
        id: action.payload
      }
    case SET_USER_TOKEN:
      return {
        ...state,
        accessToken: action.payload
      }
    case SET_USER_FIRST_NAME:
      return {
        ...state,
        firstName: action.payload
      }
    case SET_USER_LAST_NAME:
      return {
        ...state,
        lastName: action.payload
      }
    case SET_USER_EMAIL:
      return {
        ...state,
        email: action.payload
      }
    case SET_USER_PHONE:
      return {
        ...state,
        phone: action.payload
      }
    case SET_USER_ROLE:
      return {
        ...state,
        role: action.payload
      }
    case SET_USER_2FA_SMS_ENABLED:
      return {
        ...state,
        enabled2faSMS: action.payload
      }
    case SET_USER_2FA_AUTHENTICATOR_ENABLED:
      return {
        ...state,
        enabled2faAuthenticator: action.payload
      }
    case SET_USER_AUTHENTICATOR_SECRET:
      return {
        ...state,
        authenticatorSecret: action.payload
      }
    case SET_USER_AUTHENTICATOR_BACKUP:
      return {
        ...state,
        authenticatorBackup: action.payload
      }
    default:
      return state;
  }
}

export default userReducer;