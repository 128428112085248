import React from "react";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import "react-multi-email/style.css";
import "react-popper-tooltip/dist/styles.css";
import "react-day-picker/lib/style.css";
import "react-vis/dist/style.css";
import "../styles/globals.css";

import PageWithLayoutType from "@custom-types/PageWithLayoutType";
import DefaultLayout from "@layouts/Default";
import { UserContext } from "@context/UserContext";
import { OnboardingContext } from "@context/OnboardingContext";

type AppLayoutProps = {
  Component: PageWithLayoutType;
  pageProps: any;
};

function MyApp({ Component, pageProps }: AppLayoutProps) {
  const [queryClient] = React.useState(() => new QueryClient());
  const Layout = Component.layout || DefaultLayout;

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <UserContext>
          <OnboardingContext>
            {/* @ts-ignore */}
            <Layout>
              <Component {...pageProps} />
            </Layout>
            <ToastContainer
              autoClose={2500}
              hideProgressBar
              pauseOnHover={false}
              pauseOnFocusLoss={false}
            />
          </OnboardingContext>
        </UserContext>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
