import React, { createContext, useReducer } from 'react'

import UserReducer, { UserAction, UserState, initialState } from '@reducers/UserReducer'

export const UserStateContext = createContext<{
  user: UserState;
  dispatch: React.Dispatch<UserAction>
}>({
  user: initialState,
  dispatch: () => {}
})

type UserProps = {
  children?: React.ReactNode;
}

export const UserContext = ({children}: UserProps) => {
  const [user, dispatch] = useReducer(UserReducer, initialState);
  const value = {user, dispatch};

  return (
    <UserStateContext.Provider value={value}>
      {children}
    </UserStateContext.Provider>
  )
}