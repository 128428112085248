import React, { createContext, useEffect, useReducer, useState } from 'react'

import OnboardingReducer, { initialState, OnboardingAction, OnboardingState } from '@reducers/OnboardingReducer'

export const OnboardingStateContext = createContext<{
  onboarding: OnboardingState;
  dispatch: React.Dispatch<OnboardingAction>
}>({
  onboarding: initialState,
  dispatch: () => {}
})

type OnboardingProps = {
  children?: React.ReactNode;
}

export const OnboardingContext = ({children}: OnboardingProps) => {
  let localState: OnboardingState | null = null;
  if (typeof window !== 'undefined') {
    const data = localStorage.getItem('onboarding');
    if (data) {
      localState = JSON.parse(data);
    } else {
      localState = null;
    }
  }
  const [onboarding, dispatch] = useReducer(OnboardingReducer, localState || initialState);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('onboarding', JSON.stringify(onboarding));
    }
  }, [onboarding])


  const value = {onboarding, dispatch};

  return (
    <OnboardingStateContext.Provider value={value}>
      {children}
    </OnboardingStateContext.Provider>
  )
}